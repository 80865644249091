@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
@import 'react-phone-number-input/style.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
    body {
        @apply font-inter;
        @apply bg-default;
        @apply text-white;
    }

    h1 {
        @apply font-black;
        @apply text-[4rem];
        @apply leading-[130%];
    }

    h2 {
        @apply font-black;
        @apply text-[3.5rem];
        @apply leading-[140%];
    }

    h3 {
        @apply font-extrabold;
        @apply text-[2.25rem];
        @apply leading-[140%];
    }

    h4 {
        @apply font-semibold;
        @apply text-[1.5rem];
        @apply leading-[140%];
    }

    h5 {
        @apply font-medium;
        @apply text-[15px];
        @apply leading-[100%];
    }

    p {
        @apply font-normal;
        @apply text-base;
        @apply leading-[160%];
        @apply text-left;
    }

    li {
        @apply font-medium;
        @apply text-[15px];
        @apply leading-[160%];
        @apply text-left;
    }

    a {
        @apply cursor-pointer;
    }

    button {
        @apply px-8;
        @apply h-12;
        @apply rounded-tertiary;
        @apply font-medium;
        @apply leading-[100%];
    }

    select {
        cursor: pointer;
    }

    input, input:focus-visible {
        outline: none;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .otp-input {
        border: 1px solid rgba(0, 0, 0, 0.30)
    }

    .otp-input:focus {
        @apply border-2;
    }

    .otp-input-default:focus {
        @apply border-lightBrown;
    }
    .otp-input-error, .otp-input-error:focus{
        @apply border-error;
    }

    .phone-input, .phone-input input {
        background: transparent;
    }


    /** Phone input's classes overrides**/
    .phone-input .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
        color: inherit;
    }

    .phone-input .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
        box-shadow: none;
        margin-right: 0.25rem;
    }

    .phone-input .PhoneInputCountryIcon--border {
        box-shadow: none;
        margin-right: 0.25rem;
    }
}

@media print {
    html, body {
        padding: 30px;
        overflow: hidden;
    }
}
